import { render, staticRenderFns } from "./protocol.vue?vue&type=template&id=21322c86"
import script from "./protocol.vue?vue&type=script&lang=js"
export * from "./protocol.vue?vue&type=script&lang=js"
import style0 from "./protocol.vue?vue&type=style&index=0&id=21322c86&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports